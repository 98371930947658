import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Leaf1, Leaf2, about } from "../assets";
import SkillCard from "./../components/SkillCard";
const Skills = () => {
  return (
    <section
      id="skills"
      className="flex items-center justify-center flex-col gap-12 my-12"
    >
      {/* title */}
      <div className="w-full flex items-center justify-center py-24">
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: 200 }}
          exit={{ opacity: 0, width: 0 }}
          transition={{ delay: 0.4 }}
          className="flex items-center justify-around w-52"
        >
          <img src={Leaf1} alt="" className="w-6 h-auto object-contain" />
          <p className="text-white hover:bg-opacity-75">Skills</p>
          <img src={Leaf2} alt="" className="w-6 h-auto object-contain" />
        </motion.div>
      </div>
      {/* main content */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
        {/* image section */}
        <div className="w-full flex flex-col gap-4 items-center justify-center px-8">
          <SkillCard skill={"HTML 5"} percentage={"90%"} color={"#ff3f3f"} />
          <SkillCard skill={"CSS 3"} percentage={"80%"} color={"#008FFF"} />
          <SkillCard
            skill={"JavaScript"}
            percentage={"80%"}
            color={"#00FF00"}
          />
          <SkillCard skill={"PHP"} percentage={"80%"} color={"#a52a2a	"} />
          <SkillCard skill={"React.js"} percentage={"90%"} color={"#006400"} />
          <SkillCard skill={"SQL"} percentage={"85%"} color={"#008080"} />
          <SkillCard skill={"NoSQL"} percentage={"85%"} color={"#008080"} />
          <SkillCard
            skill={"Google Cloud"}
            percentage={"90%"}
            color={"	#00ffff"}
          />
          <SkillCard
            skill={"Data Analysis"}
            percentage={"90%"}
            color={"	#dc143c"}
          />
          <SkillCard
            skill={"Machine Learning"}
            percentage={"90%"}
            color={"	#ff4500"}
          />
          <SkillCard
            skill={"Data Visualization"}
            percentage={"90%"}
            color={"darkorange"}
          />
          <SkillCard
            skill={"Dashboarding"}
            percentage={"95%"}
            color={"#ff6347"}
          />
        </div>
      </div>
    </section>
  );
};

export default Skills;
