import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Leaf1, Leaf2, about } from "../assets";

const About = () => {
  return (
    <section
      id="about"
      className="flex items-center justify-center flex-col gap-12 my-12"
    >
      {/* title */}
      <div className="w-full flex items-center justify-center py-24">
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: 200 }}
          exit={{ opacity: 0, width: 0 }}
          transition={{ delay: 0.4 }}
          className="flex items-center justify-around w-52"
        >
          <img src={Leaf1} alt="" className="w-6 h-auto object-contain" />
          <p className="text-white hover:bg-opacity-75">About</p>
          <img src={Leaf2} alt="" className="w-6 h-auto object-contain" />
        </motion.div>
      </div>
      {/* main content */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
        {/* image section */}
        <div className="w-full flex items-center justify-center px-8">
          <div className="w-full lg:w-96 p-[2px] rounded-md bg-gradient-to-br from-primary to-secondary relative">
            <img
              src={about}
              alt=""
              className="w-full rounded-md h-auto object-contain"
            />
            <div className="absolute w-full h-full -top-3 -left-2 bg-gradient-to-br from-primary to-secondary rounded-md blur[5px] -z-10"></div>
          </div>
        </div>
        {/* content section */}
        <div className="w-full px-8 flex flex-col gap-4 items-start justify-start">
          <p className="text-white text-base tracking-wide text-justify">
            About Me, a passionate and dedicated individual with a strong
            foundation in Data Science. I completed my Bachelor of Computer
            Applications (BCA) with a specialization in Data Science from Arka
            Jain University in 2023. Currently, I am on a journey of further
            academic enrichment as I pursue my Master of Computer Applications
            (MCA) at Arka Jain University, with an expected graduation in 2025.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
