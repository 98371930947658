import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";

const HomeSocialLinks = ({ data, index }) => {
  const Motion = motion; // Added declaration for Motion
  const [isHovered, setisHovered] = useState(false);

  return (
    <Motion.a
      key={index}
      href={data.uri}
      initial={{ opacity: 0, y: 25 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 25 }}
      transition={{ delay: (index = 0.1) }}
      className="w-12 h-12 rounded-full bg-gradient-to-br from-primary to-secondary relative p-[2px] cursor-pointer" // Adjusted padding
      onMouseEnter={() => setisHovered(true)}
      onMouseLeave={() => setisHovered(false)}
    >
      <AnimatePresence>
        {isHovered && (
          <motion.div
            className={
              "absolute inset-1 blur-md bg-gradient-to-br from-primary to-secondary -z-10" // Fixed class name
            }
          ></motion.div>
        )}
        <div className="w-full h-full rounded-full bg-bgPrimary flex items-center justify-center">
          <data.Icon className="text-white" /> {/* Adjusted color class */}
        </div>
      </AnimatePresence>
    </Motion.a>
  );
};

export default HomeSocialLinks;
