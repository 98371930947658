import React from "react";
import { AnimatePresence } from "framer-motion";
import { Hero } from "../assets";
import HeroTypeWriter from "./../components/HeroTypeWritter";
import { Socials } from "../utils/helper";
import HomeSocialLinks from "./../components/HomeSocialLinks";

const Home = () => {
  return (
    <section
      id="home"
      className="flex items-center justify-center flex-col gap-12 relative h-screen"
    >
      <div className="flex flex-col items-center w-full max-w-4xl">
        {/* content section */}
        <h2 className="text-3xl lg:text-4xl text-white">
          Hello, I'm
          <span className="block tracking-wider text-4xl lg:text-6xl mt-4 text-white">
            Mr. Aman Srivastava
          </span>
        </h2>
        {/* typewriter */}
        <h2 className="text-2xl lg:text-4xl text-white mt-4">
          And I'm{" "}
          <HeroTypeWriter
            speed={100}
            words={["a developer..", "Data Science and Cloud enthusiast.."]}
          />
        </h2>
        <p className="text-base text-white mt-6 lg:text-left">
          Embarking on a multifaceted professional journey, I aspire to excel as
          a versatile professional in roles ranging from data analyst, business
          analyst, and data scientist to product manager and software developer.
          Eager to leverage my passion for data analysis to uncover insights,
          align technology with business strategies, and explore advanced
          analytics and machine learning, I am dedicated to contributing
          valuable expertise to drive organizational success.
        </p>

        {/* social media links */}
        <div className="flex items-center justify-center gap-16 mt-16">
          <AnimatePresence>
            {Socials &&
              Socials.map((item, index) => (
                <HomeSocialLinks key={index} data={item} index={index} />
              ))}
          </AnimatePresence>
        </div>
      </div>
    </section>
  );
};

export default Home;
