import React from "react";
import {
  Header,
  Home,
  About,
  Skills,
  Projects,
  Contact,
  ParticlesContainer,
} from "./"; // Make sure to import your components correctly

const App = () => {
  return (
    <div className="w-full xl:w-[1600px] py-32 px-4 lg:px-12 lg:pr-32 flex flex-col items-center justify-center">
      {/* Particles */}
      <ParticlesContainer />

      {/* Home container */}
      <Home />

      {/* About container */}
      <About />

      {/* Skills container */}
      <Skills />

      {/* Contact Container */}
      <Contact />
    </div>
  );
};

export default App;
