import { initializeApp, getApp, getApps } from "firebase/app"
import { getFirestore } from "firebase/firestore"
const firebaseConfig = {
    apiKey: "AIzaSyDLvwj6ck6I77X7GzBDgPwHEga_ZKipyS8",
    authDomain: "portfolio-e1b60.firebaseapp.com",
    projectId: "portfolio-e1b60",
    storageBucket: "portfolio-e1b60.appspot.com",
    messagingSenderId: "405511144863",
    appId: "1:405511144863:web:5275992899840710d5a9aa",
    measurementId: "G-HX6NNK1NF4"
};

const app = getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };