import {
  FaDiagramProject,
  FaEnvelope,
  FaFacebookF,
  FaGithub,
  FaHouse,
  FaLinkedinIn,
  FaRenren,
  FaUser,
  FaYoutube,
} from "react-icons/fa6";
import {
  ChatApp,
  CodePenClone,
  FreshJuiceUI,
  ImageSharing,
  OpenAI,
  PixabayClone,
  PortfolioFirebase,
  RestaurantClone,
  SocialMedia,
} from "../assets";

export const Socials = [
  {
    id: `linkedin-${Date.now()}`,
    Icon: FaLinkedinIn,
    uri: "https://www.linkedin.com/in/aman-srivastava-190420200/",
    color: "#0072b1",
  },
  {
    id: `github-${Date.now()}`,
    Icon: FaGithub,
    uri: "https://github.com/amnsr31",
    color: "#fff",
  }
];

export const Menus = [
  {
    id: `home-${Date.now()}`,
    Icon: FaHouse,
    uri: "#home",
    name: "Home",
  },
  {
    id: `about-${Date.now()}`,
    Icon: FaUser,
    uri: "#about",
    name: "About",
  },
  {
    id: `skills-${Date.now()}`,
    Icon: FaRenren,
    uri: "#skills",
    name: "Skills",
  },
  {
    id: `projects-${Date.now()}`,
    Icon: FaDiagramProject,
    uri: "#projects",
    name: "Projects",
  },
  {
    id: `contact-${Date.now()}`,
    Icon: FaEnvelope,
    uri: "#contact",
    name: "Contact",
  },
];

export const ProjectsData = [
  {
    id: `socialmedia-${Date.now()}`,
    name: "Amazon Sales Dashboard",
    imgSrc: SocialMedia,
    gitURL: "https://github.com/amnsr31/Amazon-Sales-Dashboard",
  },
  {
    id: `socialmedia-${Date.now()}`,
    name: "TCS Share Analysis",
    imgSrc: SocialMedia,
    gitURL: "https://github.com/amnsr31/TCS-share-analysis",
  },
  {
    id: `socialmedia-${Date.now()}`,
    name: "Car Dekho Sales Analysis",
    imgSrc: SocialMedia,
    gitURL: "https://github.com/amnsr31/Car-Dekho-Sales-Analysis",
  },
  {
    id: `socialmedia-${Date.now()}`,
    name: "Shark Tank Sales Analysis",
    imgSrc: SocialMedia,
    gitURL: "https://github.com/amnsr31/Shark-Tank-Sales-Analysis",
  },
  {
    id: `socialmedia-${Date.now()}`,
    name: "Zomato Dataset Analysis and Visulaization",
    imgSrc: SocialMedia,
    gitURL: "https://github.com/amnsr31/Zomato-Dataset-Analysis-and-Visualization",
  },
  {
    id: `socialmedia-${Date.now()}`,
    name: "Real Estate Website",
    imgSrc: SocialMedia,
    gitURL: "https://realestate-7tm.pages.dev/",
  },
  {
    id: `socialmedia-${Date.now()}`,
    name: "Food Website",
    imgSrc: SocialMedia,
    gitURL: "https://github.com/amnsr31/Food_website",
  },
  {
    id: `socialmedia-${Date.now()}`,
    name: "E-Commerce Website",
    imgSrc: SocialMedia,
    gitURL: "https://github.com/amnsr31/E-Com-Website",
  }
];
